import React, {useState, useEffect} from 'react'
import { useTheme } from '@mui/material/styles';
import { Toolbar,TableFooter,TablePagination,TableSortLabel,Table,TableBody,TableCell,TableContainer, TableHead,TableRow,Paper,Typography} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from "react-i18next"
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage'; ;
 
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t, headCellsInvoiceTickets,showEditButton,showDeleteButton } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsInvoiceTickets.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 12}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 12}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
        {showEditButton&&
        <TableCell
          key='edit'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#4e73df", fontSize: 12}}>
            {t('Button_Edit')}
          </Typography>
        </TableCell>}
         {showDeleteButton &&
        <TableCell
          key='delete'
          align='center'
          padding='normal'
          sortDirection={false}
        >
          <Typography style={{color:"#e74a3b", fontSize: 12}} > 
            {t('Button_Delete')}
          </Typography>
        </TableCell>}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
    const { textHead, textReg, numTotRegInvoiceTickets, numTotRegInvoiceTicketsFilter, textOf } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 16}} id="tableTitle" component="div">
          {textHead} 
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 16}} id="tableTitle" component="div">
          {textReg} {(numTotRegInvoiceTicketsFilter).toLocaleString('de-DE')} {textOf} {(numTotRegInvoiceTickets).toLocaleString('de-DE')}
        </Typography>
      </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataTickets']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingInvoiceTickets =(props)=>{
  const { dataInvoiceTickets, headCellsInvoiceTickets,numTotRegInvoiceTicketsFilter, titleTable,setOpenDeleteRowInvoiceTickets,setOpenEditRowInvoiceTickets,setRowEditInvoiceTickets,showEditButton,showDeleteButton,numTotRegInvoiceTickets,activeOffsetInvoiceTickets,rangeOffsetInvoiceTickets,currencyOffsetInvoiceTickets,setCurrencyOffsetInvoiceTickets} = props;
  const {t,i18n} = useTranslation(['listDataTickets']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataInvoiceTickets.length) : 0);
  const [countData,setCountData] = useState(dataInvoiceTickets.length);
  
  useEffect(
    () => {
      if (dataInvoiceTickets.length!==countData || dataInvoiceTickets.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(dataInvoiceTickets.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataInvoiceTickets.length) : 0);  
    },
    [dataInvoiceTickets,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffsetInvoiceTickets(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalDeleteRow = (row) => {
    setRowEditInvoiceTickets(row);
    setOpenDeleteRowInvoiceTickets(true);
  };

  const openEditRow = (row) => {
    setRowEditInvoiceTickets(row);
    setOpenEditRowInvoiceTickets(true);
  };
 
  const listItems= (
  stableSort(dataInvoiceTickets, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 12}} >
            {row.numberticket}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 12}} >  
            {row.vehiclestore_licenseplate}
            </Typography>  
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 12}} >  
            {row.start_date_ticket!==""?new Date(row.start_date_ticket).toLocaleDateString(i18n.language==="es"?'es-ES':'en-US'):""}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 12}} >  
            {row.rel_trans_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 12}} >  
            {row.ori_tras_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 12}} >  
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {2}
              fixedDecimalScale= {true}
              allowNegative= {false}
              id="totalstartweight" 
              name="totalstartweight"
              value={row.totalstartweight}
              style={{width:118, color:"#f5f5f5", background:"#bdbdbd",fontSize: 12, textAlign:'right', paddingRight:5}} 
              disabled
            />  
          </Typography>
        </TableCell>
        { (showEditButton  && row.id!=="" && row.id!==null )&&
        <TableCell align="center">
          <IconButton  size="large"  style={{color:"#4e73df", fontSize: 12}} onClick={()=>openEditRow(row)}>
            <EditIcon/>
          </IconButton>
        </TableCell>}
        {(showDeleteButton  && row.id!=="" && row.id!==null ) &&
        <TableCell align="center">
          <IconButton  size="large"  style={{ color:"#e74a3b", fontSize: 12}} onClick={()=>openModalDeleteRow(row)}>
            <DeleteIcon/>
          </IconButton>
        </TableCell>}
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}>      
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegInvoiceTickets={numTotRegInvoiceTickets} numTotRegInvoiceTicketsFilter={numTotRegInvoiceTicketsFilter} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} headCellsInvoiceTickets={headCellsInvoiceTickets} showEditButton={showEditButton} showDeleteButton={showDeleteButton}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={8} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetInvoiceTickets && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetInvoiceTickets"
                            name="currencyOffsetInvoiceTickets"
                            value={currencyOffsetInvoiceTickets}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:12}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetInvoiceTickets
                            }}>
                            {rangeOffsetInvoiceTickets.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={8}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingInvoiceTickets.propTypes = {
 
  dataInvoiceTickets: PropTypes.array.isRequired,
  headCellsInvoiceTickets: PropTypes.array.isRequired,
  numTotRegInvoiceTicketsFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired,
  setOpenDeleteRowInvoiceTickets: PropTypes.func.isRequired,
  setOpenEditRowInvoiceTickets: PropTypes.func.isRequired,   
  setRowEditInvoiceTickets: PropTypes.func.isRequired,  
  showEditButton: PropTypes.bool.isRequired,  
  showDeleteButton: PropTypes.bool.isRequired,    
  numTotRegInvoiceTickets: PropTypes.number.isRequired,
  activeOffsetInvoiceTickets: PropTypes.bool.isRequired,
  rangeOffsetInvoiceTickets: PropTypes.array.isRequired,
  currencyOffsetInvoiceTickets: PropTypes.number.isRequired,
  setCurrencyOffsetInvoiceTickets: PropTypes.func.isRequired 
};
  
export default TableSortingInvoiceTickets;