import React, {  useEffect, useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next" 
import { useDispatch } from "react-redux"; 
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingInvoiceTickets from "pages/tickets/ticketsEdit/invoiceTicketsEdit/SortingTablesInvoiceTickets";
import InvoiceNextTickets from "pages/tickets/ticketsEdit/invoiceTicketsEdit/InvoiceNextTickets";
import PropTypes from 'prop-types';

const initialFiltersInvoiceTickets= {
  "idFrom":"",
  "idTo":"",
  "defaultStoreIdFrom":"",
  "defaultStoreIdTo":"",
  "useraccountIdFrom":"",
  "useraccountIdTo":"",
  "isFinished":false,
  "isNotFinished":true,
  "numberticketFrom":"",
  "numberticketTo":"",
  "purchasenoteFrom":"",
  "purchasenoteTo":"",
  "ownnumbertickesFrom":"",
  "ownnumbertickesTo":"",
  "start_date_ticketFrom":"",
	"start_date_ticketTo":"",
  "end_date_ticketFrom":"",
  "end_date_tickettTo":"",
  "vehiclestore_licenseplateFrom":"",
  "vehiclestore_licenseplateTo":"",
  "rel_trans_store_idFrom":"",
  "rel_trans_store_idTo":"",
  "ori_tras_store_idFrom":"",
  "ori_tras_store_idTo":"", 
};
 
const initialStateInvoiceTickets = [{
  "id" : "",
  "numberticket":0,
  "vehiclestore_licenseplate" : "",
  "start_date_ticket" : "",
  "end_date_ticket" : "",
  "rel_trans_tradename" : "",
  "ori_tras_tradename" : "",
  "totalstartweight" : 0,
  "totalendweight" : 0,
  "estimatedprice":0, 
  "discount":0, 
  "tax":0, 
  "taxincluded":false ,
  "ticketweight":true,
  "formdi_id":0
}];

const headCellsInvoiceTickets = [
  {
    id: 'numberticket',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_NUMBERTICKETS',
    canOrder:true,
  },
  {
    id: 'vehiclestore_licenseplate',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_LICENSEPLATE',
    canOrder:true,
  },
  {
    id: 'start_date_ticket',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_START_DATE',
    canOrder:true,
  },
  {
    id: 'rel_trans_tradename',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_TRADENAME_TRANS',
    canOrder:true,
  },
  {
    id: 'ori_tras_tradename',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_NAME_STORE',
    canOrder:true,
  },
  {
    id: 'totalstartweight',
    numeric: false,
    disablePadding: false,
    label: 'Text_TICKETS_FIRST_WEIGHTS',
    canOrder:true,
  }
];

const initialStoreToSelect = [{
  "id": "",
  "tradename": ""
}];


const InvoiceTickets = ({showModalTicketsSettings,submitTickets,setSubmitTickets, configPrinter,refeshData,setRefeshData}) => {
  const { t,i18n } = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE);  
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code);  
  const [dataInvoiceTickets, setDataInvoiceTickets] = useState(initialStateInvoiceTickets);
  const [filtersInvoiceTickets, setFiltersInvoiceTickets] = useState(initialFiltersInvoiceTickets);
  const [applyFiltersInvoiceTickets, setApplyFiltersInvoiceTickets] = useState(initialFiltersInvoiceTickets);
  const [numTotRegInvoiceTickets, setNumTotRegInvoiceTickets] = useState(0);
  const [numTotRegInvoiceTicketsFilter, setNumTotRegInvoiceTicketsFilter] = useState(0);
  const [errorsFiltersInvoiceTickets, setErrorsFiltersInvoiceTickets] = useState({}); 
  const [inputTextInvoiceTickets, setInputTextInvoiceTickets] = useState(""); 
  const [openDeleteRowInvoiceTickets, setOpenDeleteRowInvoiceTickets] = useState(false);
  const [openEditRowInvoiceTickets, setOpenEditRowInvoiceTickets] = useState(false);
  const [openEditFilterInvoiceTickets, setOpenEditFilterInvoiceTickets] = useState(false);
  const [rowEditInvoiceTickets, setRowEditInvoiceTickets] = useState(initialStateInvoiceTickets[0]);
  const [isLoadingInvoiceTickets, setIsLoadingInvoiceTickets] = useState(true);
  const [isLoadingInvoiceTicketsError, setIsLoadingInvoiceTicketsError] = useState(false);
  const [idCodeDeleteInvoiceTickets, setIdCodeDeleteInvoiceTickets] = useState(DELETE_CODE);
  const [trasnporterToSelect,setTrasnporterToSelect]= useState(initialStoreToSelect); 
  
  const dispatch = useDispatch();
  const [activeOffsetInvoiceTickets, setActiveOffsetInvoiceTickets] = useState(false);
  const [currencyOffsetInvoiceTickets, setCurrencyOffsetInvoiceTickets] = useState(0);
  const [rangeOffsetInvoiceTickets, setRangeOffsetInvoiceTickets] = useState([{}]); 
 
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showModalTicketsSettings) {
        setDataInvoiceTickets(initialStateInvoiceTickets); 
        setFiltersInvoiceTickets(initialFiltersInvoiceTickets);
        setApplyFiltersInvoiceTickets(initialFiltersInvoiceTickets);
        setNumTotRegInvoiceTickets(0);
        setNumTotRegInvoiceTicketsFilter(0);
        setErrorsFiltersInvoiceTickets({}); 
        setInputTextInvoiceTickets(""); 
        setOpenDeleteRowInvoiceTickets(false);
        setOpenEditRowInvoiceTickets(false);
        setOpenEditFilterInvoiceTickets(false);
        setRowEditInvoiceTickets(initialStateInvoiceTickets[0]);
        setIsLoadingInvoiceTickets(false);
        setIsLoadingInvoiceTicketsError(false);
        setIdCodeDeleteInvoiceTickets(DELETE_CODE);
        setTrasnporterToSelect(initialStoreToSelect);
        setActiveOffsetInvoiceTickets(false);
        setCurrencyOffsetInvoiceTickets(0); 
        setRangeOffsetInvoiceTickets([{}]); 
      }
    },
    [
      setDataInvoiceTickets, 
      setFiltersInvoiceTickets,
      setApplyFiltersInvoiceTickets,
      setNumTotRegInvoiceTickets,
      setNumTotRegInvoiceTicketsFilter,
      setErrorsFiltersInvoiceTickets, 
      setInputTextInvoiceTickets, 
      setOpenDeleteRowInvoiceTickets,
      setOpenEditRowInvoiceTickets,
      setOpenEditFilterInvoiceTickets,
      setRowEditInvoiceTickets,
      setIsLoadingInvoiceTickets,
      setIsLoadingInvoiceTicketsError,
      setIdCodeDeleteInvoiceTickets,
      setTrasnporterToSelect,
      setActiveOffsetInvoiceTickets,
      setCurrencyOffsetInvoiceTickets,
      setRangeOffsetInvoiceTickets, 
      showModalTicketsSettings,  
      DELETE_CODE
    ]
  );

  const openEditFilterInvoiceTicketss = () => {
    setFiltersInvoiceTickets(applyFiltersInvoiceTickets);
    setOpenEditFilterInvoiceTickets(!openEditFilterInvoiceTickets);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFiltersInvoiceTickets(validateFormFilter());
    if (Object.keys(errorsFiltersInvoiceTickets).length === 0) {
      setApplyFiltersInvoiceTickets(filtersInvoiceTickets);
      setFiltersInvoiceTickets(initialFiltersInvoiceTickets);
      setErrorsFiltersInvoiceTickets({});
      setOpenEditFilterInvoiceTickets(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFiltersInvoiceTickets({});
    setFiltersInvoiceTickets(initialFiltersInvoiceTickets);
    setApplyFiltersInvoiceTickets(initialFiltersInvoiceTickets);
    setOpenEditFilterInvoiceTickets(!openEditFilterInvoiceTickets);
  };

  const handleCancelFilters = () => {
    setErrorsFiltersInvoiceTickets({});
    setFiltersInvoiceTickets(initialFiltersInvoiceTickets);
    setOpenEditFilterInvoiceTickets(!openEditFilterInvoiceTickets);
  };

  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFiltersInvoiceTickets(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFiltersInvoiceTickets({});
      setFiltersInvoiceTickets(initialFiltersInvoiceTickets);
      setOpenEditFilterInvoiceTickets(!openEditFilterInvoiceTickets);
    }
  };

  const validateFormFilter = () => {
    let errorsFiltersInvoiceTickets = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (filtersInvoiceTickets.idFrom!=="" & !regexNumber.test(filtersInvoiceTickets.idFrom)) {
      errorsFiltersInvoiceTickets.idFrom = 'Text_TextNoNumber';
    }
    if (filtersInvoiceTickets.idTo!=="" & !regexNumber.test(filtersInvoiceTickets.idTo)) {
      errorsFiltersInvoiceTickets.idTo = 'Text_TextNoNumber';
    }
    
    if (regexInvalidCharacters.test(filtersInvoiceTickets.numberticketFrom)) {
      errorsFiltersInvoiceTickets.numberticketFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersInvoiceTickets.numberticketTo)) {
      errorsFiltersInvoiceTickets.numberticketTo = 'Text_TextNoValid';
    } 
     
    if (filtersInvoiceTickets.idFrom!=="" & !errorsFiltersInvoiceTickets.idFrom!==undefined){
      if (filtersInvoiceTickets.idTo!=="" & !errorsFiltersInvoiceTickets.idTo!==undefined){
        if (parseInt(filtersInvoiceTickets.idTo)<parseInt(filtersInvoiceTickets.idFrom)){
          errorsFiltersInvoiceTickets.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (filtersInvoiceTickets.defaultStoreIdTo!=="" & filtersInvoiceTickets.defaultStoreIdTo<filtersInvoiceTickets.defaultStoreIdFrom){
      errorsFiltersInvoiceTickets.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filtersInvoiceTickets.useraccountIdTo!=="" & filtersInvoiceTickets.useraccountIdTo<filtersInvoiceTickets.useraccountIdFrom){
      errorsFiltersInvoiceTickets.useraccountIdTo = 'Text_TextGreater_UserAccount';
    }

    if (filtersInvoiceTickets.start_date_ticketTo!=="" & filtersInvoiceTickets.start_date_ticketTo<filtersInvoiceTickets.start_date_ticketFrom){
      errorsFiltersInvoiceTickets.start_date_ticketTo = 'Text_TextGreater_Start_Date';
    }

    if (filtersInvoiceTickets.rel_trans_store_idTo!=="" & filtersInvoiceTickets.rel_trans_store_idTo<filtersInvoiceTickets.rel_trans_store_idFrom){
      errorsFiltersInvoiceTickets.rel_trans_store_idTo = 'Text_TextGreater_Rel_Trans_Store';
    }
    
    if (filtersInvoiceTickets.numberticketTo!=="" & filtersInvoiceTickets.numberticketTo<filtersInvoiceTickets.numberticketFrom){
      errorsFiltersInvoiceTickets.numberticketTo = 'Text_TextGreater_NumberTickets';
    }

    if (filtersInvoiceTickets.ori_tras_store_idTo!=="" & filtersInvoiceTickets.ori_tras_store_idTo<filtersInvoiceTickets.ori_tras_store_idFrom){
      errorsFiltersInvoiceTickets.ori_tras_store_idTo = 'Text_TextGreater_Ori_Tras_Store';
    }

    return errorsFiltersInvoiceTickets;
  }  

  const filteredData = dataInvoiceTickets.filter((el) => {
    if (inputTextInvoiceTickets === '') {
        return el;
    }
    else {
        let dataInvoiceTicketsTranslate=t(el.id+el.vehiclestore_licenseplate+el.rel_trans_tradename+el.ori_tras_tradename+el.numberticket) ;
        return dataInvoiceTicketsTranslate.toLowerCase().includes(inputTextInvoiceTickets);
    }
  })
  
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextInvoiceTickets(lowerCase);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteInvoiceTickets(value);
  };

  const handleCancelDeleteRow = () => { 
    setRowEditInvoiceTickets(initialStateInvoiceTickets[0]); 
    setIsLoadingInvoiceTickets(false);    
    setIsLoadingInvoiceTicketsError(false);
    setIdCodeDeleteInvoiceTickets(DELETE_CODE);
    setOpenDeleteRowInvoiceTickets(false);
  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDeleteInvoiceTickets !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    if (e.key !== 'Escape') {
      if (rowEditInvoiceTickets.id !== '') {
        const fetchData = async () => {
          setIsLoadingInvoiceTickets(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `tickets/deletetickets`, token, rowEditInvoiceTickets);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
              setRefeshData(true);
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoadingInvoiceTickets(false);
          } finally {
            setIsLoadingInvoiceTickets(false);
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
  
    setIdCodeDeleteInvoiceTickets(DELETE_CODE);
    setOpenDeleteRowInvoiceTickets(false);
    handleCloseDataEdit();
  };

  const handleCloseDataEdit = () => { 
    setOpenEditRowInvoiceTickets(false); 
    setRowEditInvoiceTickets(openEditRowInvoiceTickets?rowEditInvoiceTickets:initialStateInvoiceTickets[0]);
    setIsLoadingInvoiceTickets(false);
    setIsLoadingInvoiceTicketsError(false);
    toggleDrawerDataEdit(false);
	};

  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };

  const handleChangeSelectDateFrom = (event) => {
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      start_date_ticketFrom: event!==null?new Date(event):null,
      start_date_ticketTo:null
    }));
  };

  const handleBlurSelectDateFrom = (e) => {
    handleChangeSelectDateFrom(e);
    setErrorsFiltersInvoiceTickets(validateFormFilter());
  };

  const handleChangeSelectDateTo = (event) => {
    setFiltersInvoiceTickets(filtersInvoiceTickets => ({
      ...filtersInvoiceTickets,
      start_date_ticketTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDatetTo = (e) => {
    handleChangeSelectDateTo(e);
    setErrorsFiltersInvoiceTickets(validateFormFilter());
  };
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
  useEffect(() => {
    if (numTotRegInvoiceTicketsFilter > LIMITPAGESIZE) {
      setActiveOffsetInvoiceTickets(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegInvoiceTicketsFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetInvoiceTickets(newRangeOffset);
    } else {
      setCurrencyOffsetInvoiceTickets(0);
      setActiveOffsetInvoiceTickets(false);
      setRangeOffsetInvoiceTickets([{}]);
    }
  }, [numTotRegInvoiceTicketsFilter, LIMITPAGESIZE]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataTransporter = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `tickets/getlisttransportertickets`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTrasnporterToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (showModalTicketsSettings || submitTickets) { 
      fetchDataTransporter(); 
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,applyFiltersInvoiceTickets,openEditFilterInvoiceTickets,showModalTicketsSettings,submitTickets]);
  

  useEffect(() => {
    let isMounted = true;  
    const fetchnumTotRegInvoiceTickets = async () => {
      setIsLoadingInvoiceTickets(true);
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_User_Master')))
        ? await helpHttp().get2(ENDPOINT,`tickets/getinformationtickets/`,token,false)
        : (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))
            ? await helpHttp().get8(ENDPOINT,`tickets/getinformationticketswithstore`,token,defaultStore.id,false)
            :await helpHttp().get7(ENDPOINT,`tickets/getInformationticketswithstoreanduseraccount`,token,defaultStore.id,useraccount_id,false)
            )
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataInvoiceTickets(initialStateInvoiceTickets);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegInvoiceTickets(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingInvoiceTickets(false);
      }
    };
  
    const fetchnumTotRegInvoiceTicketsFilter = async () => {
      setIsLoadingInvoiceTickets(true);
      try {
        const getData = 
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_User_Master')))?
          await helpHttp().post4(ENDPOINT,`tickets/getcountfilterticket`,token,"-1","-1",false,applyFiltersInvoiceTickets):
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master')))?
          await helpHttp().post4(ENDPOINT,`tickets/getcountfilterticket`,token,"-1",useraccount_id,false,applyFiltersInvoiceTickets):
          await helpHttp().post4(ENDPOINT,`tickets/getcountfilterticket`,token,defaultStore.id,useraccount_id,false,applyFiltersInvoiceTickets);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataInvoiceTickets(initialStateInvoiceTickets);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegInvoiceTicketsFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingInvoiceTickets(false);
      }
    };
  
    const fetchData = async () => {
      setIsLoadingInvoiceTickets(true);
      try {
        const getData = 
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_User_Master')))?
          await helpHttp().post6(ENDPOINT,`tickets/getsimplelistticket`,token,currencyOffsetInvoiceTickets,LIMITPAGESIZE,"-1","-1",false,applyFiltersInvoiceTickets):
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master')))?
          await helpHttp().post6(ENDPOINT,`tickets/getsimplelistticket`,token,currencyOffsetInvoiceTickets,LIMITPAGESIZE,"-1",useraccount_id,false,applyFiltersInvoiceTickets):
          await helpHttp().post6(ENDPOINT,`tickets/getsimplelistticket`,token,currencyOffsetInvoiceTickets,LIMITPAGESIZE,defaultStore.id,useraccount_id,false,applyFiltersInvoiceTickets);
        if (getData.err || getData.message === "Load failed") {
          setDataInvoiceTickets(initialStateInvoiceTickets);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataInvoiceTickets(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingInvoiceTickets(false);
      }
    };
  
    if (showModalTicketsSettings || submitTickets || refeshData) {    
      fetchnumTotRegInvoiceTickets();
      fetchnumTotRegInvoiceTicketsFilter();
      fetchData();
      setSubmitTickets(false);
      setIsLoadingInvoiceTickets(false);
      setRefeshData(false);
      return () => { isMounted = false; };
    }
  }, [ENDPOINT, t, token,roles, dispatch, openDeleteRowInvoiceTickets, openEditRowInvoiceTickets, rowEditInvoiceTickets, showModalTicketsSettings, LIMITPAGESIZE, applyFiltersInvoiceTickets, currencyOffsetInvoiceTickets,defaultStore.id,useraccount_id,submitTickets,setSubmitTickets,refeshData,setRefeshData]);
  
  return (
    <div> 
      {showModalTicketsSettings && ( 
        <div className="card border-info shadow">
          {/* Search input */}
          <div className="card-header 2 bg-primary text-white text-center text-bold">
            <h4>
              {isLoadingInvoiceTicketsError?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingInvoiceTickets?<CircularProgress color={openDeleteRowInvoiceTickets?"warning":"primary"}/>:<i className="fas fa-duotone fa-truck text-warning"></i>} {t('Text_TICKETS_SECOND_WEIGHTS')}
            </h4>
          </div>  
          <br/>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="searchStyle">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={inputTextInvoiceTickets}
                  placeholder={t('Text_TICKETS_SECOND_WEIGHTS_Search')} 
                  onChange={inputHandler}
                  fullWidth
                  label={t('Text_TICKETS_SECOND_WEIGHTS_Search')}>
                </TextField>
              </div>
              <br/>
              <Typography style={{color:"#067330", fontSize: 16}} >  
                {t('Text_Filter')}&nbsp;&nbsp;&nbsp;
                  <button type="button" className="buttonFilter" onClick={openEditFilterInvoiceTicketss}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersInvoiceTickets.numberticketFrom!=="" || applyFiltersInvoiceTickets.numberticketTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_TICKETS_NUMBERTICKETS')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilterInvoiceTicketss}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersInvoiceTickets.start_date_ticketFrom!=="" || applyFiltersInvoiceTickets.start_date_ticketTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_TICKETS_DATE')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilterInvoiceTicketss}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersInvoiceTickets.vehiclestore_licenseplateFrom!=="" || applyFiltersInvoiceTickets.vehiclestore_licenseplateTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_TICKETS_LICENSEPLATE')}</button><> </>
                  <button type="button" className="buttonFilter" onClick={openEditFilterInvoiceTicketss}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersInvoiceTickets.rel_trans_store_idFrom!=="" || applyFiltersInvoiceTickets.rel_trans_store_idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_TICKETS_TRADENAME_TRANS')}</button><> </> 
                  <button type="button" className="buttonFilter" onClick={openEditFilterInvoiceTicketss}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersInvoiceTickets.ori_tras_store_idFrom!=="" || applyFiltersInvoiceTickets.ori_tras_store_idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_TICKETS_TRADENAME_STORE')}</button><> </> 
              </Typography>
              <br/>
              <br/>
              <br/>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingInvoiceTickets
                    dataInvoiceTickets={filteredData}
                    headCellsInvoiceTickets={headCellsInvoiceTickets}
                    numTotRegInvoiceTicketsFilter={numTotRegInvoiceTicketsFilter}
                    titleTable={t('Text_TICKETS_SECOND_WEIGHTS_TABLE')}
                    setOpenDeleteRowInvoiceTickets={setOpenDeleteRowInvoiceTickets}
                    setOpenEditRowInvoiceTickets={setOpenEditRowInvoiceTickets}
                    setRowEditInvoiceTickets={setRowEditInvoiceTickets}
                    showEditButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Tickets_Edit'))?true:false}
                    showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_TicketsDetails_Delete'))?true:false}
                    numTotRegInvoiceTickets={numTotRegInvoiceTickets}
                    activeOffsetInvoiceTickets={activeOffsetInvoiceTickets}
                    rangeOffsetInvoiceTickets={rangeOffsetInvoiceTickets}
                    currencyOffsetInvoiceTickets={currencyOffsetInvoiceTickets}
                    setCurrencyOffsetInvoiceTickets={setCurrencyOffsetInvoiceTickets}>
                  </TableSortingInvoiceTickets>
                  {openEditFilterInvoiceTickets &&
                    <div>
                      <SwipeableDrawer
                        anchor='right'
                        open={true}
                        onClose={toggleDrawerFilterEdit(false)}
                        onOpen={toggleDrawerFilterEdit(true)}>
                        <Container >
                          <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850px' }}>
                            <br/>
                            <div id="myDIV">
                              <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                            </div>
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <form className="createfilter" onSubmit={handleCancelFilters}>  
                              <div className="form-floating mb-3 px-4 primary">
                                <label className="text-primary">{t('Text_Filter_NumberTicket_From')} :</label>
                                <div className="form-group">
                                  <TextField
                                    id="numberticketFrom" 
                                    name="numberticketFrom"
                                    value={filtersInvoiceTickets.numberticketFrom}
                                    inputProps={{ style: {width: 450}, maxLength: 15 }}
                                    label={errorsFiltersInvoiceTickets.numberticketFrom!=null?t(errorsFiltersInvoiceTickets.idFrom):t('PlaceHolder_Filter_NumberTicket_From')}
                                    placeholder={t('PlaceHolder_Filter_NumberTicket_From')}
                                    variant="standard"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}/>
                                </div>
                                <label className="text-primary">{t('Text_Filter_NumberTicket_To')} :</label>
                                <div className="form-group">
                                  <TextField
                                    id="numberticketTo" 
                                    name="numberticketTo"
                                    value={filtersInvoiceTickets.numberticketTo}
                                    inputProps={{ style: {width: 450}, maxLength: 15 }}
                                    label={errorsFiltersInvoiceTickets.numberticketTo!=null?t(errorsFiltersInvoiceTickets.idTo):t('PlaceHolder_Filter_NumberTicket_to')}
                                    placeholder={t('PlaceHolder_Filter_NumberTicket_to')}
                                    variant="standard"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}/>
                                </div>

                                <label className="text-primary">{t('Text_Filter_FirstWeight_From')} :</label>
                                <div className="form-group">
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                      id="start_date_ticketFrom" 
                                      name="start_date_ticketFrom"
                                      value={ filtersInvoiceTickets.start_date_ticketFrom!== null ? new Date(filtersInvoiceTickets.start_date_ticketFrom): null}
                                      format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                      label={errorsFiltersInvoiceTickets.start_date_ticketFrom!=null?t(errorsFiltersInvoiceTickets.start_date_ticketFrom):t('PlaceHolder_Filter_FirstWeight_From')}
                                      onBlur={handleBlurSelectDateFrom}
                                      onChange={handleChangeSelectDateFrom}
                                      slotProps={{
                                        textField: {
                                          placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                          sx: {
                                            color: '#1565c0',
                                            maxLength: '100',
                                            width: 450
                                          }
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                </div>
                                <label className="text-primary">{t('Text_Filter_FirstWeight_To')} :</label>
                                <div className="form-group">
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                      id="start_date_ticketTo" 
                                      name="start_date_ticketTo"
                                      minDate={filtersInvoiceTickets.start_date_ticketFrom!== null ? new Date(filtersInvoiceTickets.start_date_ticketFrom): null}
                                      value={ filtersInvoiceTickets.start_date_ticketTo!== null ? new Date(filtersInvoiceTickets.start_date_ticketTo): null}
                                      format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                      label={errorsFiltersInvoiceTickets.start_date_ticketTo!=null?t(errorsFiltersInvoiceTickets.start_date_ticketTo):t('PlaceHolder_Filter_FirstWeight_To')}
                                      onBlur={handleBlurSelectDatetTo}
                                      onChange={handleChangeSelectDateTo}
                                      slotProps={{
                                        textField: {
                                          placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                          sx: {
                                            color: '#1565c0',
                                            maxLength: '100',
                                            width: 450
                                          }
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                </div>
                                <label className="text-primary">{t('Text_Filter_LicensePlate_From')} :</label>
                                <div className="form-group">
                                  <TextField
                                    id="vehiclestore_licenseplateFrom" 
                                    name="vehiclestore_licenseplateFrom"
                                    value={filtersInvoiceTickets.vehiclestore_licenseplateFrom}
                                    inputProps={{ style: {width: 450}, maxLength: 15 }}
                                    label={errorsFiltersInvoiceTickets.vehiclestore_licenseplateFrom!=null?t(errorsFiltersInvoiceTickets.vehiclestore_licenseplateFrom):t('PlaceHolder_Filter_LicensePlate_From')}
                                    placeholder={t('PlaceHolder_Filter_LicensePlate_From')}
                                    variant="standard"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}/>
                                </div>
                                <label className="text-primary">{t('Text_Filter_LicensePlate_To')} :</label>
                                <div className="form-group">
                                  <TextField
                                    id="vehiclestore_licenseplateTo" 
                                    name="vehiclestore_licenseplateTo"
                                    value={filtersInvoiceTickets.vehiclestore_licenseplateTo}
                                    inputProps={{ style: {width: 450}, maxLength: 15 }}
                                    label={errorsFiltersInvoiceTickets.vehiclestore_licenseplateTo!=null?t(errorsFiltersInvoiceTickets.vehiclestore_licenseplateTo):t('PlaceHolder_Filter_LicensePlate_To')}
                                    placeholder={t('PlaceHolder_Filter_LicensePlate_To')}
                                    variant="standard"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}/>
                                </div>
                                <label className="text-primary">{t('Text_Filter_Trans_From')} :</label>
                                <div className="form-group">
                                <TextField
                                    id="rel_trans_store_idFrom"
                                    name="rel_trans_store_idFrom"
                                    value={filtersInvoiceTickets.rel_trans_store_idFrom}
                                    inputProps={{ style: {width: 450}, maxLength: 100 }}
                                    label={errorsFiltersInvoiceTickets.rel_trans_store_idFrom!=null?t(errorsFiltersInvoiceTickets.rel_trans_store_idFrom):t('PlaceHolder_Filter_Trans_From')}
                                    placeholder={t('PlaceHolder_Filter_Trans_From')}
                                    variant="outlined"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}
                                    select
                                    SelectProps={{
                                    native: true,
                                    }}>
                                    <option key={0} value=""/>
                                    {trasnporterToSelect.map((option) => (
                                        <option key={option.id} value={option.id}>
                                          {option.id+" - "+option.tradename}
                                        </option>
                                    ))}
                                  </TextField>
                                </div>
                                <label className="text-primary">{t('Text_Filter_Trans_To')} :</label>
                                <div className="form-group">
                                  <TextField
                                    id="rel_trans_store_idTo"
                                    name="rel_trans_store_idTo"
                                    value={filtersInvoiceTickets.rel_trans_store_idTo}  
                                    inputProps={{ style: {width: 450}, maxLength: 100 }}
                                    label={errorsFiltersInvoiceTickets.rel_trans_store_idTo!=null?t(errorsFiltersInvoiceTickets.rel_trans_store_idTo):t('PlaceHolder_Filter_Trans_To')}
                                    placeholder={t('PlaceHolder_Filter_Trans_To')}
                                    variant="outlined"
                                    onBlur={handleBlurFilterEdit}
                                    onChange={handleChangeFilterEdit}
                                    select
                                    SelectProps={{
                                    native: true,
                                    }}>
                                    <option key={0} value=""/>
                                    {trasnporterToSelect.filter(item=>(item.id>=filtersInvoiceTickets.rel_trans_store_idFrom)).map(option => (
                                      <option key={option.id} value={option.id}>
                                          {option.id+" - "+option.tradename}
                                      </option>
                                    ))}
                                  </TextField>
                                </div>  
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                                  <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                                </div>
                              </div>
                            </form>
                          </Box>
                        </Container>
                      </SwipeableDrawer>
                    </div>
                  }  
                  <InvoiceNextTickets 
                    openEditRowInvoiceTickets={openEditRowInvoiceTickets}
                    setOpenEditRowInvoiceTickets={setOpenEditRowInvoiceTickets}
                    id={rowEditInvoiceTickets.id.toString()}
                    configPrinter={configPrinter}
                    setRefeshData={setRefeshData}
                  />
                  <Dialog open={openDeleteRowInvoiceTickets} onClose={handleCancelDeleteRow}>
                    <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditInvoiceTickets.numberticket}  - {rowEditInvoiceTickets.vehiclestore_licenseplate} </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {t('Text_Line1')}<br/>
                        {t('Text_Line2')}
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDeleteInvoiceTickets}
                        onChange={handleChangeDeleteRow}/>
                    </DialogContent>
                    <DialogActions>
                      <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
                      <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
                    </DialogActions>
                  </Dialog>
                  {/* End Content Row */}
                </div>             
              </div>
            </div>
          </div>
        </div>
        //* End Page Content *//
      )}
     </div>
  )
}

InvoiceTickets.propTypes = {
  showModalTicketsSettings: PropTypes.bool.isRequired,
  submitTickets: PropTypes.bool.isRequired,
  setSubmitTickets: PropTypes.func.isRequired,
  configPrinter:PropTypes.object.isRequired,
  refeshData: PropTypes.bool.isRequired,
  setRefeshData: PropTypes.func.isRequired
}; 

export default InvoiceTickets;
