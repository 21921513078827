import React, {  useEffect,useCallback,useState } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"  
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material'; 
import { TextField } from '@mui/material';   
import { Button } from '@mui/material';
import { Checkbox } from '@mui/material';
import { NumericFormat } from 'react-number-format';    
import Textarea from '@mui/joy/Textarea';
import "css/generalSettings.css";  
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";  
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';  
import PropTypes from 'prop-types'; 
import TableSortingButtonArticles from "pages/settings/ticketsFormatSettings/ticketsFormatEdit/TicketsFormatTablesButtonArticles";
import TicketsCreateButtonArticle from "pages/settings/ticketsFormatSettings/ticketsFormatEdit/TicketsFormatNewButtonArticle";

const initialState = [{
  "id" : "",
  "namees" : "",
  "nameen" : "",
  "endpointexcel": "",
  "endpoinrprinter": "",
  "endpointweight": "",
  "limitsarticleperweight" : 1,
  "headerPrinter":"",
  "scale_protocol":"",
  "scale_com":"",
  "scale_delay":0,
  "scale_baud_rate":"",
  "scale_parity":"",
  "scale_data_bits":0,
  "scale_stop_bits":0,
  "scale_DTR":false,
  "scale_RTS":false,
  "scale_answer":"",
  "scale_get":"",
  "headerPrinterPurchase":"",
  "printerticket_type":"",
  "printerticket_interface":"",
  "printerticket_driver":"",
  "articlesButtonsList":[]
}];
    
const initialButtonsArticleToSelect = [{
  "button_id": 0,
  "indexrowbutton":"",
  "button_namees": "",
  "button_nameen": "",
  "button_article_id":"",
  "button_article_code": "",
  "button_article_namees": "",
  "button_article_nameen": "",
  "button_color": ""
}];

const textProtocol=[
  {"id":1,"name":"SIPI2"},
  {"id":2,"name":"Toledo DS"},
  {"id":3,"name":"EPELSA"},
  {"id":4,"name":"EPEL A"},
  {"id":5,"name":"EPEL80"},
  {"id":6,"name":"METLER"},
  {"id":7,"name":"F501"},
  {"id":8,"name":"SAIE"},
  {"id":9,"name":"MULTIPUNTO 2000"},
  {"id":10,"name":"TISA"},
  {"id":11,"name":"VD0"},
  {"id":12,"name":"VD300"},
  {"id":13,"name":"SENSOCAR"},
  {"id":14,"name":"CAS NOVITUS"},
  {"id":15,"name":"memoria fiscal"},
  {"id":17,"name":"none"}
];
const textCom=[
  {"id":1,"name":"COM1"},
  {"id":2,"name":"COM2"},
  {"id":3,"name":"COM3"},
  {"id":4,"name":"COM4"},
  {"id":5,"name":"/dev/tty.usbmodem14601"}
];

const textBaudRate=[
  {"id":1,"name":"9600"},
  {"id":2,"name":"19200"},
  {"id":3,"name":"38400"},
  {"id":4,"name":"57600"},
  {"id":5,"name":"115200"}
];
const textParity=[
  {"id":1,"name":"None"},
  {"id":2,"name":"Odd"},
  {"id":3,"name":"Even"},
  {"id":4,"name":"Mark"},
  {"id":5,"name":"Space"}
];

const EditOrCreateTicketsFormat = ({ id, showTicketsFormatSettings}) => {
  const {t,i18n} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT);  
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const [formFrom, setFormForm] = useState(initialState[0]);
  const [errorsForm, setErrorsForm] = useState({});
  const [openDeleteRowButtonsArticles, setOpenDeleteRowButtonsArticles] = useState(false);
  const [openEditRowButtonsArticles, setOpenEditRowButtonsArticles] = useState(false);
  const [editRowButtonsArticles, setEditRowButtonsArticles] = useState(initialButtonsArticleToSelect);
  const [idCodeDeleteButtonArticles,setIdCodeDeleteButtonArticles]=useState(DELETE_CODE);
  const [foundNameesArticleFormat, setFoundNameesArticleFormat ]= useState(false);
  const [foundNameenArticleFormat, setFoundNameenArticleFormat ]= useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
   
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showTicketsFormatSettings) {
        setFormForm(initialState[0]);
        setErrorsForm({}); 
        setFormForm(formFrom => ({
          ...formFrom,
          articlesButtonsList:[]
        }));
        setOpenDeleteRowButtonsArticles(false);
        setOpenEditRowButtonsArticles(false);
        setEditRowButtonsArticles(initialButtonsArticleToSelect);
        setIdCodeDeleteButtonArticles(DELETE_CODE);
        setFoundNameesArticleFormat(false);
        setFoundNameenArticleFormat(false);
        navigate("/ticketsformat") 
        return () => {};
      }
    },
    [ setFormForm,
      setErrorsForm, 
      setOpenDeleteRowButtonsArticles,
      setOpenEditRowButtonsArticles,
      setEditRowButtonsArticles,
      setIdCodeDeleteButtonArticles,
      setFoundNameesArticleFormat,
      setFoundNameenArticleFormat,
      showTicketsFormatSettings, 
      navigate,
      DELETE_CODE
    ]
  );

  const cancelModalEditOrCreateTicketsFormat = () => { 
    setFormForm(initialState[0]);
    setErrorsForm({}); 
    setFormForm(formFrom => ({
      ...formFrom,
      articlesButtonsList:[]
    }))
    setOpenDeleteRowButtonsArticles(false);
    setOpenEditRowButtonsArticles(false);
    setEditRowButtonsArticles(initialButtonsArticleToSelect);
    setIdCodeDeleteButtonArticles(DELETE_CODE);
    setFoundNameesArticleFormat(false);
    setFoundNameenArticleFormat(false);
    navigate("/ticketsformat") ;
    return () => {};
  };

  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setFormForm(formFrom => ({
      ...formFrom,
      [name]: value
    }));
  };
 
  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrorsForm(validateForm());
  };
      
  const handleChangeDataEditNumer = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","")):0;
    setFormForm(formFrom => ({
      ...formFrom,
      [name]: newValue,
    }));
  };

  const handleBlurDataEditNumber = (e) => {
    handleChangeDataEditNumer(e);
    setErrorsForm(validateForm());
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value >= 1 && value <= 100) return true;
    return false;
  };

  const withValueCap2 = (inputObj) => {
    const { value } = inputObj;
    if (value >= 1 && value <= 100000) return true;
    return false;
  };

  const handleChangeEnabledDTR = (e) => {
    setFormForm(formFrom => ({
      ...formFrom,
      scale_DTR: !formFrom.scale_DTR
    }));
  };

  const handleChangeEnabledRTS = (e) => {
    setFormForm(formFrom => ({
      ...formFrom,
      scale_RTS: !formFrom.scale_RTS
    }));
  };

  const validateForm = () => {
    let errorsForm = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (!formFrom.namees || formFrom.namees==="") {
      errorsForm.namees = 'Text_FieldNameesRequired';
    } else {
      if (regexInvalidCharacters.test(formFrom.namees)) {
        errorsForm.namees = 'Text_TextNoValid';
      } else{
        if (foundNameesArticleFormat){
          errorsForm.namees='Text_TicketsFormat_NameesExist';
        } 
      }  
    }

    if (!formFrom.nameen || formFrom.nameen==="") {
      errorsForm.nameen = 'Text_FieldNameenRequired';
    } else {
      if (regexInvalidCharacters.test(formFrom.nameen)) {
        errorsForm.nameen = 'Text_TextNoValid';
      } else{
        if (foundNameenArticleFormat){
          errorsForm.nameen='Text_TicketsFormat_NameenExist';
        } 
      }  
    }

    if (!formFrom.endpointexcel || formFrom.endpointexcel==="") {
      errorsForm.endpointexcel = 'Text_FieldEndPointRequired';
    }
    if (!formFrom.endpoinrprinter || formFrom.endpoinrprinter==="") {
      errorsForm.endpoinrprinter = 'Text_FieldEndPointRequired';
    }
    if (!formFrom.endpointweight || formFrom.endpointweight==="") {
      errorsForm.endpointweight = 'Text_FieldEndPointRequired';
    }
    
    if (regexInvalidCharacters.test(formFrom.scale_protocol)) {
      errorsForm.scale_protocol = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formFrom.scale_com)) {
      errorsForm.scale_com = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formFrom.scale_delay)) {
      errorsForm.scale_delay = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formFrom.scale_baud_rate)) {
      errorsForm.scale_baud_rate = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formFrom.scale_parity)) {
      errorsForm.scale_parity = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formFrom.scale_data_bits)) {
      errorsForm.scale_data_bits = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formFrom.scale_stop_bits)) {
      errorsForm.scale_stop_bits = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formFrom.printerticket_type)) {
      errorsForm.printerticket_type = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formFrom.printerticket_interface)) {
      errorsForm.printerticket_interface = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formFrom.printerticket_driver)) {
      errorsForm.printerticket_driver = 'Text_TextNoValid';
    } 
   
    return errorsForm;
  };

  const handleCancelDeleteRowButtonArticle = () => {
    setIdCodeDeleteButtonArticles(DELETE_CODE);
    setOpenDeleteRowButtonsArticles(false);
  };

  const handleChangeDeleteButtonRowArticle = (e) => {
    const value  = e.target.value;
    setIdCodeDeleteButtonArticles(value);
  };

  const handleCloseDeleteRowButtonArticle = (e) => {
    e.preventDefault();
    if (idCodeDeleteButtonArticles!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=formFrom.articlesButtonsList.findIndex(((item) => item.indexrowbutton === editRowButtonsArticles.indexrowbutton));
      if ( indexDelete !== -1 ) {
        formFrom.articlesButtonsList.splice( indexDelete, 1 );
      }
      setFormForm(formFrom => ({
        ...formFrom,
      }))
      setIdCodeDeleteButtonArticles(DELETE_CODE);
      setOpenDeleteRowButtonsArticles(false);
    }
  };

  const handleSubmitDataEditArticlesFormat = () => {
    const errors = validateForm();
    setErrorsForm(errors);
    if ((Object.keys(errors).length === 0) && formFrom.namees!=='' && formFrom.nameen!=='' ) {
      const fetchData = async () => {
        let isMounted = true;
        try {
          const getData = (formFrom.id === undefined || formFrom.id === "" || formFrom.id === "0" || formFrom.id === 0)
            ? await helpHttp().post(ENDPOINT, `ticketsformat/saveticketsformat`, token,formFrom)
            : await helpHttp().put(ENDPOINT, `ticketsformat/updateticketsformat`, token,formFrom);
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            cancelModalEditOrCreateTicketsFormat();
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };
      fetchData();
    } else {
      if (errors.namees){
        dispatch(warningErrorAction(t(errors.namees)));
      }else{
        if (errors.nameen){
          dispatch(warningErrorAction(t(errors.nameen)));
        }else{
          dispatch(warningErrorAction(t('Text_IncorrectData')));
        }   
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchExistNamees = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `ticketsformat/getexistticketsformatnamees/`, token,formFrom.namees);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundNameesArticleFormat(getData!==formFrom.id && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formFrom.namees!==""){
      fetchExistNamees();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,formFrom.id,formFrom.namees]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNameen = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `ticketsformat/getexistticketsformatnameen/`, token,formFrom.nameen);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundNameenArticleFormat(getData!==formFrom.id && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (formFrom.nameen!==""){
      fetchExistNameen();
    }
    return () => { isMounted = false };
  }, [ENDPOINT,t,token,dispatch,i18n,formFrom.id,formFrom.nameen]);


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
         
  useEffect(() => {
    let isMounted = true;
    const fetchDataTicketsFormat = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `ticketsformat/getticketsformatbyid/`, token, id);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setFormForm(initialState[0]);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
         
          setFormForm(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (id !== "0") {
      if (formFrom.id === "" || formFrom.id === 0) {
        fetchDataTicketsFormat(); 
      }
    } else {
      setFormForm(initialState[0]);  
      setFormForm(formFrom => ({
        ...formFrom,
        articlesButtonsList:[]
      }))
    }
    return () => { isMounted = false };
  
  }, [ENDPOINT,dispatch,id,t,token,formFrom.id]);   

  return (
    <>
      {showTicketsFormatSettings && (
        <div className="container-fluid">
          <Box sx={{ bgcolor: '#ffffff', width: '1150px'}}>
            <form className="createticketsformat" onSubmit={cancelModalEditOrCreateTicketsFormat}>  
              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className={"card border-info shadow"}>
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div id="myDIV">  
                            <h1 className={"h2NewStyle mb-2"}>
                              <i className="fas fa-regular fa-address-card fa text-warning"></i>&nbsp;&nbsp;{formFrom.namees}
                            </h1>
                            <div>
                              <button type="button" className="buttonCreate" onClick={handleSubmitDataEditArticlesFormat}>
                              {(id===undefined || id==="" || id==="0" || id===0)?t('Button_Create'):t('Button_Update')}
                              </button><> </>
                              <button type="button" className="buttonBack" onClick={cancelModalEditOrCreateTicketsFormat}>{t('Button_Cancel')}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className={"card border-info shadow"}>
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="row p-2"> 
                          <div className="form-group">   
                            <h6 className="h7NewStyle mb-2">{t('Text_TicketsFormat_Config')}</h6>
                            {/* Divider */}
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <label className="text-primary">{t('Text_TicketsFormat_NameEs')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="namees" 
                              name="namees"
                              value={formFrom.namees}
                              inputProps={{ style: {width: i18n.language==='es'?719:733}, maxLength: 100 }}
                              label={errorsForm.namees!=null?t(errorsForm.namees):t('PlaceHolder_TicketsFormat_NameEs')}
                              placeholder={t('PlaceHolder_TicketsFormat_NameEs')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                          </div>
                        </div>
                        <div className="row p-2"> 
                          <div className="form-group">   
                            <label className="text-primary">{t('Text_TicketsFormat_NameEn')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="nameen" 
                              name="nameen"
                              value={formFrom.nameen}
                              inputProps={{ style: {width: i18n.language==='es'?733:739}, maxLength: 100 }}
                              label={errorsForm.nameen!=null?t(errorsForm.nameen):t('PlaceHolder_TicketsFormat_NameEn')}
                              placeholder={t('PlaceHolder_TicketsFormat_NameEn')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                          </div>
                        </div>
                        <div className="row p-2"> 
                          <div className="form-group">   
                            <label className="text-primary">{t('Text_TicketsFormat_EndPoint_Excel')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="endpointexcel" 
                              name="endpointexcel"
                              value={formFrom.endpointexcel}
                              inputProps={{ style: {width: i18n.language==='es'?569:579}, maxLength: 100 }}
                              label={errorsForm.endpointexcel!=null?t(errorsForm.endpointexcel):t('PlaceHolder_TicketsFormat_EndPoint_Excel')}
                              placeholder={t('PlaceHolder_TicketsFormat_EndPoint_Excel')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                          </div>
                        </div>
                        <div className="row p-2"> 
                          <div className="form-group">   
                            <label className="text-primary">{t('Text_TicketsFormat_EndPoint_Printer')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="endpoinrprinter" 
                              name="endpoinrprinter"
                              value={formFrom.endpoinrprinter}
                              inputProps={{ style: {width: i18n.language==='es'?500:573}, maxLength: 100 }}
                              label={errorsForm.endpoinrprinter!=null?t(errorsForm.endpoinrprinter):t('PlaceHolder_TicketsFormat_EndPoint_Printer')}
                              placeholder={t('PlaceHolder_TicketsFormat_EndPoint_Printer')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                          </div>
                        </div>
                        <div className="row p-2"> 
                          <div className="form-group">   
                            <label className="text-primary">{t('Text_TicketsFormat_EndPoint')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="endpointweight" 
                              name="endpointweight"
                              value={formFrom.endpointweight}
                              inputProps={{ style: {width: i18n.language==='es'?550:549}, maxLength: 100 }}
                              label={errorsForm.endpointweight!=null?t(errorsForm.endpointweight):t('PlaceHolder_TicketsFormat_EndPoint')}
                              placeholder={t('PlaceHolder_TicketsFormat_EndPoint')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                          </div>
                        </div>
                        <div className="row p-2"> 
                          <div className="form-group">  
                            <label className="text-primary">{t('Text_TicketsFormat_Limit')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <NumericFormat 
                              thousandSeparator={','} 
                              decimalSeparator={'.'} 
                              decimalScale= {0}
                              fixedDecimalScale= {true}
                              allowNegative= {false}
                              isAllowed={withValueCap}
                              id="limitsarticleperweight" 
                              name="limitsarticleperweight"
                              value={formFrom.limitsarticleperweight}
                              label={errorsForm.limitsarticleperweight!=null?t(errorsForm.limitsarticleperweight):t('Text_TicketsFormat_Limit')}
                              placeholder={t('Text_TicketsFormat_Limit')}
                              style={{width: 100}}
                              customInput={TextField}
                              variant="standard"
                              onBlur={handleBlurDataEditNumber}
                              onChange={handleChangeDataEditNumer}
                            /> 
                          </div>
                        </div>
                        <div className="row p-2"> 
                          <div className="form-group">  
                            <h6 className="h7NewStyle mb-2">{t('Text_Type_ArticlesButtons')}</h6>
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <TableSortingButtonArticles 
                              dataButtonsArticles={formFrom.articlesButtonsList} 
                              numTotRegButtonsArticles={formFrom.articlesButtonsList.length}
                              setOpenDeleteRowButtonsArticles={setOpenDeleteRowButtonsArticles}
                              setOpenEditRowButtonsArticles={setOpenEditRowButtonsArticles}
                              setEditRowButtonsArticles={setEditRowButtonsArticles} 
                              LIMITPAGESIZE={LIMITPAGESIZE}
                            />
                             <TicketsCreateButtonArticle 
                                openEditRowButtonsArticles={openEditRowButtonsArticles}
                                setOpenEditRowButtonsArticles={setOpenEditRowButtonsArticles} 
                                formFrom={formFrom} 
                                setFormForm={setFormForm}
                              />
                          </div>
                        </div> 
                        <div className="row p-2"> 
                          <div className="form-group">  
                            <h6 className="h7NewStyle mb-2">{t('Text_TicketsFormat_Param')}</h6>
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <label className="text-primary">{t('Text_TicketsFormat_Printer_Type')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="printerticket_type" 
                              name="printerticket_type"
                              value={formFrom.printerticket_type}
                              inputProps={{ style: {width: i18n.language==='es'?883:956}, maxLength: 100 }}
                              label={errorsForm.printerticket_type!=null?t(errorsForm.printerticket_type):t('PlaceHolder_TicketsFormat_Printer_Type')}
                              placeholder={t('PlaceHolder_TicketsFormat_Printer_Type')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                            <label className="text-primary">{t('Text_TicketsFormat_Printer_Interface')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="printerticket_interface" 
                              name="printerticket_interface"
                              value={formFrom.printerticket_interface}
                              inputProps={{ style: {width: i18n.language==='es'?873:928}, maxLength: 100 }}
                              label={errorsForm.printerticket_interface!=null?t(errorsForm.printerticket_interface):t('PlaceHolder_TicketsFormat_Printer_Interface')}
                              placeholder={t('PlaceHolder_TicketsFormat_Printer_Interface')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                            <label className="text-primary">{t('Text_TicketsFormat_Printer_Driver')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="printerticket_driver" 
                              name="printerticket_driver"
                              value={formFrom.printerticket_driver}
                              inputProps={{ style: {width: i18n.language==='es'?893:948}, maxLength: 100 }}
                              label={errorsForm.printerticket_driver!=null?t(errorsForm.printerticket_driver):t('PlaceHolder_TicketsFormat_Printer_Driver')}
                              placeholder={t('PlaceHolder_TicketsFormat_Printer_Driver')}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                          </div>
                        </div> 

                        <div className="row p-2"> 
                          <div className="form-group">  
                            <h6 className="h7NewStyle mb-2">{t('Text_TicketsFormat_Header_Printer')}</h6>
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <Textarea 
                              id="headerPrinter" 
                              name="headerPrinter"
                              value={formFrom.headerPrinter}
                              label={errorsForm.headerPrinter!=null?t(errorsForm.headerPrinter):t('PlaceHolde_TicketsFormat_Header_Printer')}
                              placeholder={t('PlaceHolde_TicketsFormat_Header_Printer')}
                              sx={{ width: '700px' }}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              maxRows={4}
                            /> 
                          </div>
                        </div> 

                        <div className="row p-2"> 
                          <div className="form-group">  
                            <h6 className="h7NewStyle mb-2">{t('Text_TicketsFormat_Scale_Param')}</h6>
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_Protocol')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="scale_protocol"
                              name="scale_protocol"
                              value={ (formFrom.scale_protocol === undefined || formFrom.scale_protocol ===null || formFrom.scale_protocol ==="")?"SIPI2":formFrom.scale_protocol}
                              inputProps={{ style: {width: 150}, maxLength: 100, }}
                              variant="outlined"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              helperText={errorsForm.accept!=null?t(errorsForm.accept):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: (formFrom.scale_protocol === undefined || formFrom.scale_protocol ===null || formFrom.scale_protocol ==="")?"SIPI2": formFrom.scale_protocol
                              }}>
                              {textProtocol.map((option) => (
                                  <option key={option.id} value={option.name}>
                                      {option.name}
                                  </option>
                              ))}
                            </TextField>  
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_Com')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="scale_com"
                              name="scale_com"
                              value={ (formFrom.scale_com === undefined || formFrom.scale_com ===null || formFrom.scale_com ==="")?"COM1":formFrom.scale_com}
                              inputProps={{ style: {width: 50}, maxLength: 100, }}
                              variant="outlined"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              helperText={errorsForm.accept!=null?t(errorsForm.accept):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: (formFrom.scale_com === undefined || formFrom.scale_com ===null || formFrom.scale_com ==="")?"COM1": formFrom.scale_com
                              }}>
                              {textCom.map((option) => (
                                  <option key={option.id} value={option.name}>
                                      {option.name}
                                  </option>
                              ))}
                            </TextField>  
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_Delay')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <NumericFormat 
                              thousandSeparator={','} 
                              decimalSeparator={'.'} 
                              decimalScale= {0}
                              fixedDecimalScale= {true}
                              allowNegative= {false}
                              isAllowed={withValueCap2}
                              id="scale_delay" 
                              name="scale_delay"
                              value={formFrom.scale_delay}
                              label={errorsForm.scale_delay!=null?t(errorsForm.scale_delay):t('Text_TicketsFormat_Scale_Delay')}
                              placeholder={t('Text_TicketsFormat_Scale_Delay')}
                              style={{width: 150}}
                              customInput={TextField}
                              variant="outlined"
                              onBlur={handleBlurDataEditNumber}
                              onChange={handleChangeDataEditNumer}
                            /> 
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_Baud')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="scale_baud_rate"
                              name="scale_baud_rate"
                              value={ (formFrom.scale_baud_rate === undefined || formFrom.scale_baud_rate ===null || formFrom.scale_baud_rate ==="")?"9600":formFrom.scale_baud_rate}
                              inputProps={{ style: {width: 100}, maxLength: 100, }}
                              variant="outlined"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              helperText={errorsForm.accept!=null?t(errorsForm.accept):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: (formFrom.scale_baud_rate === undefined || formFrom.scale_baud_rate ===null || formFrom.scale_baud_rate ==="")?"9600": formFrom.scale_baud_rate
                              }}>
                              {textBaudRate.map((option) => (
                                  <option key={option.id} value={option.name}>
                                      {option.name}
                                  </option>
                              ))}
                            </TextField>
                          </div>
                        </div> 
                        <div className="row p-2"> 
                          <div className="form-group"> 
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_Parity')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="scale_parity"
                              name="scale_parity"
                              value={ (formFrom.scale_parity === undefined || formFrom.scale_parity ===null || formFrom.scale_parity ==="")?"None":formFrom.scale_parity}
                              inputProps={{ style: {width: 100}, maxLength: 100, }}
                              variant="outlined"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              helperText={errorsForm.accept!=null?t(errorsForm.accept):""}
                              select
                              SelectProps={{
                                  native: true,
                                  value: (formFrom.scale_parity === undefined || formFrom.scale_parity ===null || formFrom.scale_parity ==="")?"None": formFrom.scale_parity
                              }}>
                              {textParity.map((option) => (
                                  <option key={option.id} value={option.name}>
                                      {option.name}
                                  </option>
                              ))}
                            </TextField>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_Data_Bits')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <NumericFormat 
                              thousandSeparator={','} 
                              decimalSeparator={'.'} 
                              decimalScale= {2}
                              fixedDecimalScale= {true}
                              allowNegative= {false}
                              isAllowed={withValueCap2}
                              id="scale_data_bits" 
                              name="scale_data_bits"
                              value={formFrom.scale_data_bits}
                              label={errorsForm.scale_data_bits!=null?t(errorsForm.scale_data_bits):t('Text_TicketsFormat_Scale_Data_Bits')}
                              placeholder={t('Text_TicketsFormat_Scale_Data_Bits')}
                              style={{width: 150}}
                              customInput={TextField}
                              variant="outlined"
                              onBlur={handleBlurDataEditNumber}
                              onChange={handleChangeDataEditNumer}
                            /> 
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_Stop_Bits')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <NumericFormat 
                              thousandSeparator={','} 
                              decimalSeparator={'.'} 
                              decimalScale= {2}
                              fixedDecimalScale= {true}
                              allowNegative= {false}
                              isAllowed={withValueCap2}
                              id="scale_stop_bits" 
                              name="scale_stop_bits"
                              value={formFrom.scale_stop_bits}
                              label={errorsForm.scale_stop_bits!=null?t(errorsForm.scale_stop_bits):t('Text_TicketsFormat_Scale_Stop_Bits')}
                              placeholder={t('Text_TicketsFormat_Scale_Stop_Bits')}
                              style={{width: 150}}
                              customInput={TextField}
                              variant="outlined"
                              onBlur={handleBlurDataEditNumber}
                              onChange={handleChangeDataEditNumer}
                            /> 
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_DTR')}</label>  
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Checkbox
                              id="scale_DTR" 
                              name="scale_DTR"
                              checked={formFrom.scale_DTR}
                              onChange={handleChangeEnabledDTR}
                              variant="outlined"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_RTS')}</label>  
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Checkbox
                              id="scale_RTS" 
                              name="scale_RTS"
                              checked={formFrom.scale_RTS}
                              onChange={handleChangeEnabledRTS}
                              variant="outlined"
                            />
                          </div>
                        </div> 
                        <div className="row p-2"> 
                          <div className="form-group"> 
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_Answer')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                              id="scale_answer" 
                              name="scale_answer"
                              value={formFrom.scale_answer}
                              inputProps={{ style: {width: 410}, maxLength: 100 }}
                              label={errorsForm.scale_answer!=null?t(errorsForm.scale_answer):t('Text_TicketsFormat_Scale_Answer')}
                              placeholder={t('Text_TicketsFormat_Scale_Answer')}
                              variant="outlined"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_TicketsFormat_Scale_Get')} :</label>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <TextField
                                id="scale_get" 
                                name="scale_get"
                                value={formFrom.scale_get}
                                inputProps={{ style: {width: 410}, maxLength: 100 }}
                                label={errorsForm.scale_get!=null?t(errorsForm.scale_get):t('Text_TicketsFormat_Scale_Get')}
                                placeholder={t('Text_TicketsFormat_Scale_Get')}
                                variant="outlined"
                                onBlur={handleBlurDataEdit}
                                onChange={handleChangeDataEdit}
                              />
                          </div>
                        </div> 

                        <div className="row p-2"> 
                          <div className="form-group">  
                            <h6 className="h7NewStyle mb-2">{t('Text_PurchaseFormat_Header_Printer')}</h6>
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <Textarea 
                              id="headerPrinterPurchase" 
                              name="headerPrinterPurchase"
                              value={formFrom.headerPrinterPurchase}
                              label={errorsForm.headerPrinterPurchase!=null?t(errorsForm.headerPrinterPurchase):t('PlaceHolde_PurchaseFormat_Header_Printer')}
                              placeholder={t('PlaceHolde_PurchaseFormat_Header_Printer')}
                              sx={{ width: '700px' }}
                              variant="standard"
                              onBlur={handleBlurDataEdit}
                              onChange={handleChangeDataEdit}
                              maxRows={8}
                            /> 
                          </div>
                        </div> 

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Box>
          <br/> 
          <div>
            <Dialog open={openDeleteRowButtonsArticles} onClose={handleCancelDeleteRowButtonArticle}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')}  {editRowButtonsArticles.id} - {i18n.language==='es'?editRowButtonsArticles.button_namees:editRowButtonsArticles.button_nameen} </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDeleteButtonArticles}
                  onChange={handleChangeDeleteButtonRowArticle}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowButtonArticle}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowButtonArticle}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )} 
    </>
  )
}

EditOrCreateTicketsFormat.propTypes = {
  id: PropTypes.string.isRequired,
  showTicketsFormatSettings: PropTypes.bool.isRequired
};

export default EditOrCreateTicketsFormat;